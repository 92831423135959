import { result } from "lodash"
import { CONFIG } from "../config"
import { addUserObjectToRequest, callBackendAPI } from "../utils"
import { ALL_RATE_FETCH, ALL_RATE_FETCH_FAILED, ALL_RATE_FETCH_SUCCESS, BOOK_RATE_CHANGE_AMOUNTRM, BOOK_RATE_CHANGE_BENE, BOOK_RATE_CHANGE_COUNTRY, BOOK_RATE_CHANGE_PROVIDER, BOOK_RATE_CHANGE_PURPOSE, BOOK_RATE_CONFIRM_CREATE, BOOK_RATE_CONFIRM_CREATE_EXPIRED, BOOK_RATE_CONFIRM_CREATE_FAILED, BOOK_RATE_CONFIRM_CREATE_SUCCESS, BOOK_RATE_CONVERT_MONEY, BOOK_RATE_CONVERT_POINT, BOOK_RATE_CREATE, BOOK_RATE_CREATE_FAILED, BOOK_RATE_DISMISS_MESSAGE, BOOK_RATE_FETCH, BOOK_RATE_FETCH_FAILED, BOOK_RATE_FETCH_SUCCESS, BOOK_RATE_POINT_REDEEM, BOOK_RATE_PRE_CREATE, BOOK_RATE_PRE_CREATE_FAILED, BOOK_RATE_PRE_CREATE_SUCCESS, BOOK_RATE_SHOW_MESSAGE, CLEAR_SELECTED_BENE, RESET_BOOK_RATE_STATE } from "./type"

export const fetchRates = (lang: any, bene_country: any) => {
    return async (dispatch: any) => {
        try {
            dispatch({ type: BOOK_RATE_FETCH })
            let input: any = {
                ver: CONFIG.ver,
                m1: "GetRates",
                lang: lang
            }
            input = addUserObjectToRequest(input);
            let headers = {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
            let resultsFromAPI = await callBackendAPI('getrates', 'POST', headers, input);
            if (!resultsFromAPI) {
                return dispatch({ type: BOOK_RATE_FETCH_FAILED, payload: "001: Something went wrong, try again" });
            }
            if (resultsFromAPI?.error !== undefined) {
                return dispatch({ type: BOOK_RATE_FETCH_FAILED, payload: "002: Something went wrong, please check your Internet Connection and try again" });
            }
            if (!resultsFromAPI.hasOwnProperty("results")) {
                return dispatch({ type: BOOK_RATE_FETCH_FAILED, payload: "003: Something went wrong, try again" });
            }
            if (resultsFromAPI?.results) {
                let results: any = resultsFromAPI.results;
                if (results.code !== 0)
                    return dispatch({ type: BOOK_RATE_FETCH_FAILED, payload: `Error Code: ${results.code} :: ${results.message || ""}` });

                if (bene_country) {
                    let countryIndex = results.message.findIndex((i: { country: any }) => i?.country === bene_country)
                    if (countryIndex === -1) {
                        return dispatch({ type: BOOK_RATE_FETCH_FAILED, payload: `${bene_country + ' Not available yet' || ""}` });
                    }

                    dispatch({ type: BOOK_RATE_CHANGE_COUNTRY, payload: countryIndex, payload2: bene_country })
                }

                return dispatch({ type: BOOK_RATE_FETCH_SUCCESS, payload: results.message })
            }
        }
        catch (e) {
            console.log(e);
            return dispatch({ type: BOOK_RATE_FETCH_FAILED, payload: "Oops something went wrong!" })
        }
    }
}

export const fetchWhatsAppRates = (lang: any, bene_country: any, app_id: any, aa_uuid: any) => {
    return async (dispatch: any) => {
        try {
            dispatch({ type: BOOK_RATE_FETCH })
            let input: any = {
                ver: CONFIG.ver,
                app_id: app_id,
                m1: "GetRates",
                lang: lang,
                user: {
                    "aa_uuid": aa_uuid
                }
            }
            let headers = {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
            let resultsFromAPI = await callBackendAPI('whatsapp/getrates', 'POST', headers, input);
            if (!resultsFromAPI) {
                return dispatch({ type: BOOK_RATE_FETCH_FAILED, payload: "001: Something went wrong, try again" });
            }
            if (resultsFromAPI?.error !== undefined) {
                return dispatch({ type: BOOK_RATE_FETCH_FAILED, payload: "002: Something went wrong, please check your Internet Connection and try again" });
            }
            if (!resultsFromAPI.hasOwnProperty("results")) {
                return dispatch({ type: BOOK_RATE_FETCH_FAILED, payload: "003: Something went wrong, try again" });
            }
            if (resultsFromAPI?.results) {
                let results: any = resultsFromAPI.results;
                if (results.code !== 0)
                    return dispatch({ type: BOOK_RATE_FETCH_FAILED, payload: `Error Code: ${results.code} :: ${results.message || ""}` });

                if (bene_country) {
                    let countryIndex = results.message.findIndex((i: { country: any }) => i?.country === bene_country)
                    if (countryIndex === -1) {
                        return dispatch({ type: BOOK_RATE_FETCH_FAILED, payload: `${bene_country + ' Not available yet' || ""}` });
                    }

                    dispatch({ type: BOOK_RATE_CHANGE_COUNTRY, payload: countryIndex, payload2: bene_country })
                }

                // const filteredResults = results.message.filter((item: { country: string }) =>
                //     ['Indonesia', 'Bangladesh', 'Nepal'].includes(item.country)
                // );
                const filteredResults = results.message
                    .filter((item: { country: string }) =>
                        ['Indonesia', 'Bangladesh', 'Nepal'].includes(item.country)
                    )
                    .map((item: { country: string, providers: any[] }) => ({
                        ...item,
                        providers: item.country === 'Indonesia'
                            ? item.providers
                            : item.providers.filter(provider => provider.pickup_method === 1)
                    }))
                    .filter((item: { providers: string | any[] }) => item.providers.length > 0);
                return dispatch({ type: BOOK_RATE_FETCH_SUCCESS, payload: filteredResults })
            }
        }
        catch (e) {
            console.log(e);
            return dispatch({ type: BOOK_RATE_FETCH_FAILED, payload: "Oops something went wrong!" })
        }
    }
}

export const fetchAllRates = (lang: any) => {
    return async (dispatch: any) => {
        try {
            dispatch({ type: ALL_RATE_FETCH })
            let input: any = {
                ver: CONFIG.ver,
                act: 39000,
                m1: "GetAllRates",
                lang: lang
            }
            input = addUserObjectToRequest(input);
            let headers = {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
            let resultsFromAPI = await callBackendAPI('request', 'POST', headers, input);
            if (!resultsFromAPI) {
                return dispatch({ type: ALL_RATE_FETCH_FAILED, payload: "001: Something went wrong, try again" });
            }
            if (resultsFromAPI?.error !== undefined) {
                return dispatch({ type: ALL_RATE_FETCH_FAILED, payload: "002: Something went wrong, please check your Internet Connection and try again" });
            }
            if (!resultsFromAPI.hasOwnProperty("results")) {
                return dispatch({ type: ALL_RATE_FETCH_FAILED, payload: "003: Something went wrong, try again" });
            }
            if (resultsFromAPI?.results) {
                let results: any = resultsFromAPI.results;
                if (results.code !== 0)
                    return dispatch({ type: ALL_RATE_FETCH_FAILED, payload: `Error Code: ${results.code} :: ${results.message || ""}` });

                return dispatch({ type: ALL_RATE_FETCH_SUCCESS, payload: results.message })
            }
        }
        catch (e) {
            console.log(e);
            return dispatch({ type: ALL_RATE_FETCH_FAILED, payload: "Oops something went wrong!" })
        }
    }
}

export const changeCountry = (countryIndex: any, countryValue: any) => {
    return { type: BOOK_RATE_CHANGE_COUNTRY, payload: countryIndex, payload2: countryValue }
}

export const changeProvider = (providerIndex: any) => {
    return { type: BOOK_RATE_CHANGE_PROVIDER, payload: providerIndex }
}

export const changeProviderLabel = (providerValue: any) => {
    return { type: BOOK_RATE_CHANGE_PROVIDER, payload: providerValue }
}

export const changeAmountRm = (amount: any) => {
    return { type: BOOK_RATE_CHANGE_AMOUNTRM, payload: amount }
}

export const showErrorMessage = (show = false, message = '') => {
    return { type: BOOK_RATE_SHOW_MESSAGE, payload: { show, message } };
}

export const selectBene = (bene: any) => {
    return { type: BOOK_RATE_CHANGE_BENE, payload: bene }
}

export const selectPurpose = (purpose: any) => {
    return { type: BOOK_RATE_CHANGE_PURPOSE, payload: purpose }
}

export const clearSelectedBene = () => {
    return { type: CLEAR_SELECTED_BENE }
}

export const resetBookRate = () => {
    return { type: RESET_BOOK_RATE_STATE }
}

export const createBookingTransaction =
    (
        lang = 'bm',
        menuInfo: any,
        rates: any,
        country: any,
        provider: any,
        amountRM: any,
        benes: any,
        selectedBene: any,
        purposes: any,
        selectedPurpose: any,
        navigate: any,
    ) => {
        return async (dispatch: any) => {
            try {
                if (selectBene === undefined)
                    return dispatch({ type: BOOK_RATE_CREATE_FAILED, payload: "Please select a bene" })
                if (selectedPurpose === undefined)
                    return dispatch({ type: BOOK_RATE_CREATE_FAILED, payload: "Please select a purpose" });
                if (rates === undefined || rates.length === 0)
                    return dispatch({ type: BOOK_RATE_CREATE_FAILED, payload: "001: Something went wrong, try again" });
                if (country < 0) //if taking index, index = -1 issue
                    return dispatch({ type: BOOK_RATE_CREATE_FAILED, payload: "002: Something went wrong, try again" });
                if (!rates[country].hasOwnProperty("country"))
                    return dispatch({ type: BOOK_RATE_CREATE_FAILED, payload: "003: Something went wrong, try again" });
                let selectedCountry = rates[country].country;
                if (provider < 0) //if taking index, index = -1 issue
                    return dispatch({ type: BOOK_RATE_CREATE_FAILED, payload: "004: Something went wrong, try again" });
                if (!rates[country].hasOwnProperty("providers"))
                    return dispatch({ type: BOOK_RATE_CREATE_FAILED, payload: "005: Something went wrong, try again" });
                let selectedProvider = rates[country].providers[provider];
                if (amountRM === undefined)
                    return dispatch({ type: BOOK_RATE_CREATE_FAILED, payload: "006: Something went wrong, try again" });
                if (benes === undefined || benes.length === 0)
                    return dispatch({ type: BOOK_RATE_CREATE_FAILED, payload: "007: Something went wrong, try again" });
                let bene = benes[selectedBene];
                if (purposes === undefined || purposes.length === 0)
                    return dispatch({ type: BOOK_RATE_CREATE_FAILED, payload: "008: Something went wrong, try again" });
                let purpose = purposes[selectedPurpose];
                if (menuInfo === undefined)
                    dispatch({ type: BOOK_RATE_CREATE_FAILED, payload: "009: Something went wrong, try again" });
                if (!menuInfo.hasOwnProperty("act_id"))
                    return dispatch({ type: BOOK_RATE_CREATE_FAILED, payload: "010: Something went wrong, try again" });

                dispatch({ type: BOOK_RATE_CREATE });

                let input: any = {
                    ver: CONFIG.ver,
                    act: menuInfo.act_id,
                    p1: selectedCountry,
                    p2: selectedProvider,
                    p3: amountRM,
                    p4: bene,
                    p5: purpose,
                    m1: menuInfo.short_name || '',
                    lg: lang
                }
                input = addUserObjectToRequest(input);
                let headers = {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
                let resultsFromAPI = await callBackendAPI('request', 'POST', headers, input);
                if (!resultsFromAPI) {
                    return dispatch({ type: BOOK_RATE_CREATE_FAILED, payload: "011: Something went wrong, try again" });
                }
                if (resultsFromAPI?.error !== undefined) {
                    return dispatch({ type: BOOK_RATE_CREATE_FAILED, payload: "012: Something went wrong, please check your Internet Connection and try again" });
                }
                if (!resultsFromAPI.hasOwnProperty("results")) {
                    return dispatch({ type: BOOK_RATE_CREATE_FAILED, payload: "013: Something went wrong, try again" });
                }
                if (resultsFromAPI?.results) {
                    let results: any = resultsFromAPI.results;
                    if (results.code !== 0)
                        return dispatch({ type: BOOK_RATE_CREATE_FAILED, payload: `Error Code: ${results.code} :: ${results.message || ""}` });
                }
            }
            catch (e) {
                console.log(e);
                return dispatch({ type: BOOK_RATE_CREATE_FAILED, payload: "99: Something went wrong, try again" });
            }
        }
    }

export const createPreBooking =
    (
        lang: any,
        navigation: any,
        params: any,
        req_send_amount: any,
        card_no: any,
        remittance_purpose_id: any,
        country: any,
        provider: any,
        currency_type: any,
        bank: any,
        ic_account_no: any
    ) => {
        return async (dispatch: any) => {
            try {
                dispatch({ type: BOOK_RATE_PRE_CREATE });
                if (params === undefined)
                    return dispatch({ type: BOOK_RATE_PRE_CREATE_FAILED, payload: "002: Oops Something went wrong, Login in again" })
                if (!params.hasOwnProperty("act_id"))
                    return dispatch({ type: BOOK_RATE_PRE_CREATE_FAILED, payload: "003: Oops Something went wrong, Login in again" })

                let act_id = params.act_id,
                    m1 = params.name || '';
                let input: any = {
                    ver: CONFIG.ver,
                    act: act_id,
                    p1: {
                        user_trans_id: new Date().valueOf(),
                        req_send_amount: req_send_amount,
                        card_no: card_no,
                        remittance_purpose_id: remittance_purpose_id,
                        country: country,
                        provider: provider,
                        currency_type: currency_type,
                        bank: bank,
                        ic_account_no: ic_account_no
                    },
                    lg: lang
                }
                console.log("booking input", input)
                input = addUserObjectToRequest(input);
                let headers = {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
                let resultsFromAPI = await callBackendAPI('request', 'POST', headers, input);
                if (!resultsFromAPI) {
                    return dispatch({ type: BOOK_RATE_PRE_CREATE_FAILED, payload: "005: Something went wrong, try again" });
                }
                if (resultsFromAPI?.error !== undefined) {
                    return dispatch({ type: BOOK_RATE_PRE_CREATE_FAILED, payload: "006: Something went wrong, please check your Internet Connection and try again" });
                }
                if (!resultsFromAPI.hasOwnProperty("results")) {
                    return dispatch({ type: BOOK_RATE_PRE_CREATE_FAILED, payload: "007: Something went wrong, try again" });
                }
                if (resultsFromAPI?.results) {
                    let results: any = resultsFromAPI.results;
                    if (results.code !== 0)
                        return dispatch({ type: BOOK_RATE_PRE_CREATE_FAILED, payload: `Error Code: ${results.code} :: ${results.message || ""}` });

                    if (results.hasOwnProperty("providers")) {
                        let providers = results.providers;
                        dispatch({ type: BOOK_RATE_PRE_CREATE_SUCCESS, payload: providers.header, payload2: providers.body });
                        return navigation.navigate('BookRateConfirmation');
                    }
                }
            }
            catch (e) {
                console.log(e);
                return dispatch({ type: BOOK_RATE_PRE_CREATE_FAILED, payload: "Oops Something went wrong" });
            }
        }
    }

export const createBooking = (lang: any, user: any, params: any, preBook: any, points: any) => {
    return async (dispatch: any) => {
        try {
            dispatch({ type: BOOK_RATE_CONFIRM_CREATE });
            if (params === undefined)
                return dispatch({ type: BOOK_RATE_CONFIRM_CREATE_FAILED, payload: "002: Oops Something went wrong, Login in again" })
            if (!params.hasOwnProperty("act_id"))
                return dispatch({ type: BOOK_RATE_CONFIRM_CREATE_FAILED, payload: "003: Oops Something went wrong, Login in again" })

            let act_id = params.act_id,
                m1 = params.name || '';
            let input: any = {
                ver: CONFIG.ver,
                act: act_id,
                user_agent: navigator.userAgent,
                app_id: CONFIG.app_id,
                p1: preBook,
                p2: points,
                lg: lang
            }
            input = addUserObjectToRequest(input);
            let headers = {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
            let resultsFromAPI = await callBackendAPI('request', 'POST', headers, input);
            if (!resultsFromAPI) {
                return dispatch({ type: BOOK_RATE_CONFIRM_CREATE_FAILED, payload: "005: Something went wrong, try again" });
            }
            if (resultsFromAPI?.error !== undefined) {
                return dispatch({ type: BOOK_RATE_CONFIRM_CREATE_FAILED, payload: "006: Something went wrong, please check your Internet Connection and try again" });
            }
            if (!resultsFromAPI.hasOwnProperty("results")) {
                return dispatch({ type: BOOK_RATE_CONFIRM_CREATE_FAILED, payload: "007: Something went wrong, try again" });
            }
            if (resultsFromAPI?.results) {
                let results: any = resultsFromAPI.results;
                if (results.code === -7)
                    return dispatch({ type: BOOK_RATE_CONFIRM_CREATE_EXPIRED, payload: `Error Code: ${results.code} :: ${results.message || ""}` });

                if (results.code !== 0)
                    return dispatch({ type: BOOK_RATE_CONFIRM_CREATE_FAILED, payload: `Error Code: ${results.code} :: ${results.message || ""}` });

                if (results.hasOwnProperty("message")) {
                    return dispatch({ type: BOOK_RATE_CONFIRM_CREATE_SUCCESS, payload: results.resp, payload2: `${results.message || ""}` });
                }
            }
        }
        catch (e) {
            console.log(e);
            return dispatch({ type: BOOK_RATE_CONFIRM_CREATE_FAILED, payload: "Oops Something went wrong" })
        }
    }
}

export const createWhatsAppBooking = (lang: any, user: any, params: any, preBook: any, points: any, hash: any, app_id: any) => {
    return async (dispatch: any) => {
        try {
            dispatch({ type: BOOK_RATE_CONFIRM_CREATE });
            if (params === undefined)
                return dispatch({ type: BOOK_RATE_CONFIRM_CREATE_FAILED, payload: "002: Oops Something went wrong, Login in again" })
            if (!params.hasOwnProperty("act_id"))
                return dispatch({ type: BOOK_RATE_CONFIRM_CREATE_FAILED, payload: "003: Oops Something went wrong, Login in again" })

            let act_id = params.act_id,
                m1 = params.name || '';
            let input: any = {
                ver: CONFIG.ver,
                act: act_id,
                user_agent: navigator.userAgent,
                app_id: String(app_id === 2 ? CONFIG.app_id : app_id),
                p1: preBook,
                p2: points,
                lg: lang,
                hash: hash,
                user: user
            }
            let headers = {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
            let resultsFromAPI = await callBackendAPI('whatsapp/request', 'POST', headers, input);
            if (!resultsFromAPI) {
                return dispatch({ type: BOOK_RATE_CONFIRM_CREATE_FAILED, payload: "005: Something went wrong, try again" });
            }
            if (resultsFromAPI?.error !== undefined) {
                return dispatch({ type: BOOK_RATE_CONFIRM_CREATE_FAILED, payload: "006: Something went wrong, please check your Internet Connection and try again" });
            }
            if (!resultsFromAPI.hasOwnProperty("results")) {
                return dispatch({ type: BOOK_RATE_CONFIRM_CREATE_FAILED, payload: "007: Something went wrong, try again" });
            }
            if (resultsFromAPI?.results) {
                let results: any = resultsFromAPI.results;
                if (results.code !== 0)
                    return dispatch({ type: BOOK_RATE_CONFIRM_CREATE_FAILED, payload: `Error Code: ${results.code} :: ${results.message || ""}` });

                if (results.hasOwnProperty("message")) {
                    return dispatch({ type: BOOK_RATE_CONFIRM_CREATE_SUCCESS, payload: results.resp, payload2: `${results.message || ""}` });
                }
            }
        }
        catch (e) {
            console.log(e);
            return dispatch({ type: BOOK_RATE_CONFIRM_CREATE_FAILED, payload: "Oops Something went wrong" })
        }
    }
}

export const bookRateDimissMessage = () => {
    return { type: BOOK_RATE_DISMISS_MESSAGE }
}

export const bookRatePointRedeem = (redeem: any) => {
    return { type: BOOK_RATE_POINT_REDEEM, payload: redeem }
}

export const bookRateConvertPoint = (value: any) => {
    let points = value / 100
    return { type: BOOK_RATE_CONVERT_POINT, payload: points }
}

export const bookRateConvertMoney = (service_charge: any, points: any, send_amount?: any) => {

    let discountAmount
    let usedPoint
    let discountNewAmount

    let discountSendAmount
    let remainPoint


    if (points >= (service_charge * 100)) {

        discountNewAmount = service_charge
        usedPoint = (service_charge * 100)

        remainPoint = points - usedPoint

        if (remainPoint > 0) {

            if (remainPoint >= (parseFloat(send_amount) * 100)) {
                discountNewAmount = Math.round((Number(service_charge) + Number(send_amount)) * 100) / 100
                usedPoint = usedPoint + (parseFloat(send_amount) * 100)

            }
            else {

                discountSendAmount = (remainPoint / 100)
                discountNewAmount = Math.round((Number(service_charge) + discountSendAmount) * 100) / 100
                usedPoint = usedPoint + remainPoint
            }

        }
    } else {
        discountAmount = (points / 100)
        discountNewAmount = Math.round(discountAmount * 100) / 100
        usedPoint = points
    }

    if (points === 0) {
        usedPoint = 0
        discountNewAmount = 0
    }

    // if (points >= (service_charge*100)){
    //   discountNewAmount = service_charge
    //   usedPoint = (service_charge*100)
    // } else {
    //   discountAmount = (points/100)
    //   discountNewAmount = Math.round(discountAmount * 100) / 100
    //   usedPoint = points
    // }

    // if(points === 0){
    //   usedPoint = 0
    //   discountNewAmount = 0
    // }
    return { type: BOOK_RATE_CONVERT_MONEY, payload: { discountAmount: discountNewAmount, usedPoint: usedPoint } }
}